<template>
    <div class="login">
      <router-link :to="{ name: 'index' }">
        <h2 class="login__title" ref="logo">DATAFORCE</h2>
      </router-link>

        <div class="login__wrapper">
            <h1 class="login__subtitle">{{ title }}</h1>

            <form class="login__form" @submit.prevent="submit()" autocomplete="on">
                <div class="login__field _static-placeholder">
                    <div class="login__input">
                        <s-input
                            icon="person_outline"
                            iconPosition="left"
                            type="text"
                            name="логин"
                            placeholder="Ваш логин"
                            v-model="form.login"
                            :error="errors.login"
                            ref="login"
                            :disabled="preloader"
                            autocomplete
                            size="small"
                        />
                    </div>
                </div>

                <div class="login__field _static-placeholder">
                    <div class="login__input">
                        <s-password
                            icon="lock_open"
                            iconPosition="left"
                            name="пароль"
                            type="password"
                            placeholder="Введите новый пароль"
                            v-model="form.password"
                            :error="errors.password"
                            ref="password"
                            :disabled="preloader"
                            autocomplete
                            size="small"
                        />
                    </div>
                </div>

                <div v-if="preloader" class="login__preloader">
                    <div class="login__preloader-dots"></div>
                </div>

                <v-button v-else class="login__button" action="submit" ref="submit" :disabled="preloader">
                    Войти
                </v-button>

                <!-- <div class="login__service">
                    <router-link class="login__link" :to="{ name: 'authLostpass' }">Забыли пароль?</router-link>
                    <router-link class="login__link" :to="{ name: 'authReg' }">Регистрация</router-link>
                </div> -->
            </form>
        </div>

        <div class="login__copy">© {{ $moment().format('YYYY') }}, Dataforce</div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { logIn } from '@/function/auth.js'

    export default {
      metaInfo() {
        return {
          title: 'Вход в личный кабинет DATAFORCE',
          meta: [
            {
              name: 'description',
              content: 'Страница входа в личный кабинет платформы DATAFORCE'
            }
          ]
        }
      },

        data() {
            return {
                title: 'Вход',

                preloader: undefined,

                form: {
                    login: '',
                    password: '',
                    // ttl: 15
                },

                errors: {}
            }
        },

        computed: {
            ...mapState({
                config: state => state.config,
                request: state => state.request,
            })
        },

        watch: {
            'form.login'(val) {
                this.$set(this.errors, 'login', undefined)
            },

            'form.password'(val) {
                this.$set(this.errors, 'password', undefined)
            }
        },

        methods: {
            /* Установка фокуса при ошибке */
            viewErrors(ref, error = 'Ошибка авторизации') {
                if (ref) {
                    /* Установка ошибки инпута */
                    this.$set(this.errors, ref, error)

                    /* Установка фокуса на инпут */
                    this.$refs[ref].$el.querySelector('input').focus()
                }

                this.$notify({
                    type: 'error',
                    title: error
                });

                if (!ref) {
                    setTimeout(() => {
                        this.$set(this, 'preloader', false)
                    }, 750)
                }

                /* Возвращение ошибки */
                return error
            },

            /* Отправка запроса */
            async submit() {
                try {
                    if (!this.form.login) {
                        this.viewErrors('login', 'Введите логин')
                        return false
                    }

                    if (!this.form.password) {
                        this.viewErrors('password', 'Введите пароль')
                        return false
                    }

                    this.$set(this, 'preloader', true)

                    this.request({
                        type: 'POST',
                        url: '/auth',
                        body: this.form,
                        apiName: 'main',
                    }).then(async response => {
                        let tokens = response.data;
                        if (tokens.access_token && tokens.refresh_token) {
                            await logIn(this.config, tokens)

                            if (this.$route.query.to) {
                                this.$router.push({ path: this.$route.query.to })
                            } else {
                                this.$router.push({ name: 'dashboard' })
                            }
                        } else {
                            this.viewErrors()
                        }
                    }, error => {
                        this.viewErrors()
                    });
                } catch (_) {
                    this.viewErrors()
                }
            }
        }
    }
</script>

<style lang="scss" scoped src="../styles/auth.scss" />
